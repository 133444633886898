<template>
    <div class="row pb-4">
        <div class="xs2 primary--text text-xs-center">
            <!-- <div class="dot" /> -->
            <i
                class="mdi mdi-timelapse primary--text"
                style="font-size: 72px"
            ></i>
            <div style="font-size: 42px" class="font-bold">
                {{ moment(item.gioXuatBenDuKien).format("HH:mm") }}
            </div>
            <div
                style="font-size: 16px; text-transform: uppercase"
                class="pb-3"
            >
                Xuất bến
            </div>
            <div class="line"></div>
        </div>
        <div class="xs10">
            <div
                class="item container shadow mx-3"
                style="border-radius: 20px"
                @click="xemChiTietXe"
            >
                <div class="row">
                    <div class="xs9">
                        <div class="pt-2">
                            <div
                                class="font-24"
                                style="white-space: break-spaces"
                            >
                                {{ item.nhaXe_TenNhaXe || item.tenDoanhNghiep }}
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="xs6">
                                <div class="row align-center font-20">
                                    <i class="pr-2 mdi mdi-counter icon"></i>
                                    <div class="font-24">
                                        {{ item.xe_BienSoXe }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row pt-2">
                            <div class="xs6">
                                <div class="row align-center">
                                    <i
                                        class="pr-2 mdi mdi-phone-outline icon"
                                    ></i>
                                    <div class="font-24">
                                        {{
                                            item.xe_SoDienThoai ||
                                            "Chưa cung cấp"
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="xs6">
                                <div class="row align-center">
                                    <i
                                        class="pr-2 mdi mdi-map-marker-outline icon"
                                    ></i>
                                    <div class="font-24">
                                        {{
                                            item.loTrinh.diemDung.tenDiemDung ||
                                            ""
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="xs6">
                                <div class="row align-center font-24">
                                    <i
                                        class="pr-2 mdi mdi-seat-outline icon"
                                    ></i>
                                    <div class="font-24">
                                        {{
                                            `${
                                                item.soGheNgoiTrong +
                                                    item.soGiuongNamTrong || 0
                                            } Chỗ trống`
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="xs6">
                                <div class="row align-center font-24">
                                    <i
                                        class="pr-2 mdi mdi-star-outline icon"
                                    ></i>
                                    <div class="font-24">
                                        {{
                                            `${item.danhGiaTrungBinh || 0} (${
                                                item.tongSoDanhGia || 0
                                            }&nbsp`
                                        }}
                                    </div>
                                    đánh giá)
                                </div>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <!-- <div class="xs6"> -->
                            <div class="row align-center">
                                <i
                                    class="pr-2 mdi mdi-information-outline icon"
                                ></i>
                                <div
                                    class="font-24"
                                    style="white-space: break-spaces"
                                >
                                    {{ item.tenTrangThaiChuyenDi }}
                                </div>
                            </div>
                            <!-- </div> -->
                            <!-- <div class="xs6"></div> -->
                        </div>
                        <!-- <slot name="ThongBaoDacBietSlot"></slot> -->
                        <div id="ThongBaoDacBiet">
                            <div class="font-24 success--text pt-2">
                                {{
                                    item.soGheNgoiTrong +
                                        item.soGiuongNamTrong ==
                                    0
                                        ? "Xe chỉ còn ghế phụ. Nhân viên sẽ xếp chỗ khi lên xe"
                                        : ""
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="xs3">
                        <div
                            class="column justify-space-between align-end"
                            style="text-align: right"
                        >
                            <div class="font-bold primary--text">
                                <div style="font-size: 38px">
                                    {{ `${$MoneyFormat(item.giaVeMin)}đ` }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DxButton
                id="btn-chonxe"
                text="Chọn xe"
                type="normal"
                width="163px"
                height="58px"
                styling-mode="contained"
                class="secondary--bg white--text"
                style="
                    border-radius: 10px;
                    position: absolute;
                    right: 40px;
                    bottom: 50px;
                "
                v-if="
                    $Helper.CheckRole(
                        $t('Quyen.BanVeTaiBen'),
                        $t('Quyen.BanVePosDoc')
                    )
                "
                @click="chonXe"
            />
        </div>
    </div>
</template>
<script>
import { DxButton } from "devextreme-vue";
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
    components: { DxButton },
    props: {
        item: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters("chiTietChuyenDi", ["getThongTinXeDangChon"]),
    },
    methods: {
        ...mapMutations("timKiemChuyenDi", {
            setDialogChiTietChuyenDi: "setDialogChiTietChuyenDi",
            setOldDiemDung: "setOld",
        }),
        ...mapMutations("chiTietChuyenDi", ["setThongTinXeDangChon"]),
        ...mapMutations("banDo", ["setOld"]),
        ...mapMutations("soDoCho", [
            "removeDanhSachChoDaChonLocal",
            "removeTatCaChoDaChon",
            "setDanhSachChoDaChon",
        ]),
        ...mapActions("banDo", ["xoaDsLoTrinh", "xoaDiemDung"]),
        ...mapActions("timKiemChuyenDi", ["kiemTraTrangThaiChuyenDi"]),
        xemChiTietXe() {
            try {
                this.setOld({ key: "loTrinh", value: [] });
                this.setOldDiemDung({ key: "diemDung", value: "" });
                this.xoaDsLoTrinh();
                this.xoaDiemDung();
            } catch (error) {
                console.log(
                    "🚀 ~ file: ItemDanhSachChuyenDi.vue ~ line 207 ~ xemChiTietXe ~ error",
                    error
                );
            } finally {
                this.setThongTinXeDangChon(this.item);
                this.setDialogChiTietChuyenDi(true);
            }
        },
        async chonXe() {
            this.removeTatCaChoDaChon();
            this.setDanhSachChoDaChon([]);
            this.removeDanhSachChoDaChonLocal();
            this.setThongTinXeDangChon(this.item);
            //Xe hết chỗ
            if (!this.item) {
                return console.log("item null");
            }
            //Kiểm tra để mua vé
            let check = await this.kiemTraTrangThaiChuyenDi({
                idXe: this.getThongTinXeDangChon.id,
                soKhach: 0,
            });
            if (!check.data) {
                this.$emit("trangThaiChuyenDi", check);
                return;
            }
            let query = {
                doanhNghiep_Id: this.item.doanhNghiep_Id,
                xe_Id: this.item.xe_Id,
                chuyenDi_Id: this.item.id,
                nhaXe_Id: this.item.nhaXe_Id,
                xe_HangXe: this.item.xe_HangXe,
                xe_BienSoXe: this.item.xe_BienSoXe,
                soChoNgoi: this.item.soChoNgoi,
                idLoTrinh: this.item.loTrinh.idLoTrinh || "",
                xeHetCho: false,
                xeKhongCoSoDoCho: this.item.xeKhongSoDoCho,
            };
            if (this.item.soGheNgoiTrong + this.item.soGiuongNamTrong <= 0) {
                query.xeHetCho = true;
                this.$router.push({
                    path: "thong-tin-thanh-toan",
                    query: query,
                });
                return;
            }
            //Chọn chỗ
            this.$router.push({
                path: "/chon-vi-tri-cho-ngoi",
                query: query,
            });
        },
    },
};
</script>
<style scoped></style>
<style lang="scss" scoped>
.dot {
    position: relative;
    width: 61px;
    height: 61px;
    border-radius: 50px;
    border: 3px solid $primary;
    margin: 0 auto;
}
.dot::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    background-color: $primary;
    width: 39px;
    height: 39px;
    border-radius: 50px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 8px;
}
.line {
    border: 2px dashed $primary;
    height: 78px;
    width: 1px;
    margin: 0 auto;
}
::v-deep#btn-chonxe span {
    font-size: 24px;
}
.icon {
    font-size: 32px;
}
.item:active {
    background-color: $primary;
    opacity: 0.1;
}
</style>
